export const CommonBtn = () => import('../..\\components\\common\\common_btn.vue' /* webpackChunkName: "components/common-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonOfficeBtn = () => import('../..\\components\\common\\office_btn.vue' /* webpackChunkName: "components/common-office-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonSuperSlide = () => import('../..\\components\\common\\super_slide.vue' /* webpackChunkName: "components/common-super-slide" */).then(c => wrapFunctional(c.default || c))
export const CommonSvgIcon = () => import('../..\\components\\common\\svg_icon.vue' /* webpackChunkName: "components/common-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const BusinessDsGoods = () => import('../..\\components\\business\\ds-goods.vue' /* webpackChunkName: "components/business-ds-goods" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormDialogLessonNew = () => import('../..\\components\\business\\form-dialog-lesson-new.vue' /* webpackChunkName: "components/business-form-dialog-lesson-new" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormDialogNewMain = () => import('../..\\components\\business\\form-dialog-new-main.vue' /* webpackChunkName: "components/business-form-dialog-new-main" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormDialogNew = () => import('../..\\components\\business\\form-dialog-new.vue' /* webpackChunkName: "components/business-form-dialog-new" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormDialog = () => import('../..\\components\\business\\form-dialog.vue' /* webpackChunkName: "components/business-form-dialog" */).then(c => wrapFunctional(c.default || c))
export const BusinessFormPage = () => import('../..\\components\\business\\form-page.vue' /* webpackChunkName: "components/business-form-page" */).then(c => wrapFunctional(c.default || c))
export const BusinessImgGroup = () => import('../..\\components\\business\\img-group.vue' /* webpackChunkName: "components/business-img-group" */).then(c => wrapFunctional(c.default || c))
export const BusinessOutlineInfo = () => import('../..\\components\\business\\outline-info.vue' /* webpackChunkName: "components/business-outline-info" */).then(c => wrapFunctional(c.default || c))
export const BusinessPosDialog = () => import('../..\\components\\business\\pos-dialog.vue' /* webpackChunkName: "components/business-pos-dialog" */).then(c => wrapFunctional(c.default || c))
export const BusinessSubmitSuccess = () => import('../..\\components\\business\\submit-success.vue' /* webpackChunkName: "components/business-submit-success" */).then(c => wrapFunctional(c.default || c))
export const BusinessTeacherInfo = () => import('../..\\components\\business\\teacher-info.vue' /* webpackChunkName: "components/business-teacher-info" */).then(c => wrapFunctional(c.default || c))
export const BusinessVerificationCode = () => import('../..\\components\\business\\verification_code.vue' /* webpackChunkName: "components/business-verification-code" */).then(c => wrapFunctional(c.default || c))
export const BusinessVideoDialog = () => import('../..\\components\\business\\video-dialog.vue' /* webpackChunkName: "components/business-video-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
